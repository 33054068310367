import { Collapse, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { TextFieldLimit } from 'components'
import { memo, useEffect, useMemo, useState } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'
import { IssueStatuses, IssueTypes, IssueValuesKeys } from 'types/enums'
import { CaptureSection } from 'types/issueTypes'
import { dataTestId } from 'utils'
import TextField from '../../components/Base/TextField'
import { ISSUE_VIEW_PAGE } from '../../consts/selectorIds'
import { MAX_INTEGER } from '../../consts/values'
import { useFieldWithError } from '../matrix'
import { checkCharactersLimit } from './utils'

const useStyles = makeStyles((theme: AbbTheme) => ({
    field: {
        marginTop: theme.spacing(1),
    },
}))

export interface NcInformationAboutIssueProps
    extends React.HtmlHTMLAttributes<HTMLDivElement>,
        Partial<{
            lightMode: boolean
            onCaptureSectionChanged?: (
                key: keyof CaptureSection,
                value: any
            ) => void
            issueType: IssueTypes
            captureSection?: CaptureSection
            disabled: boolean
        }> {}

const InformationAboutIssue = (props: NcInformationAboutIssueProps) => {
    const {
        lightMode,
        disabled,
        className: classNameProp,
        issueType = IssueTypes.Internal,
        captureSection: captureSectionProp,
        onCaptureSectionChanged,
        ...other
    } = props
    const [state, setState] = useState<CaptureSection>({
        subject: '',
        summary: null,
        whatIsHappening: '',
        whyIsThisAProblem: '',
        howDoWeKnowWeHaveAProblem: '',
        whereDoesDidProblemHappen: '',
        whenDoesDidTheProblemHappen: '',
        whatAreCustomerExpectations: '',
        whoIsAffectedByTheProblem: '',
        howManyDefectsFound: 0,
    })
    const [stopScroll, setStopScroll] = useState(true)
    useEffect(() => {
        setState({ ...captureSectionProp })
    }, [captureSectionProp])
    const classes = useStyles(props)

    const howManyDefectsFoundError =
        MAX_INTEGER <= Number(state?.howManyDefectsFound)
    const handleFormChange = (key: keyof CaptureSection) =>
        onCaptureSectionChanged && onCaptureSectionChanged(key, state[key])

    const isCorrectStatus = useMemo(() => {
        return [IssueStatuses.Draft, IssueStatuses.New].includes(
            captureSectionProp?.issueStatusId
        )
    }, [captureSectionProp?.issueStatusId, IssueStatuses.Draft])

    const subjectField = useFieldWithError(IssueValuesKeys.Subject, {
        disabled,
        defaultError:
            isCorrectStatus &&
            checkCharactersLimit(state?.subject?.length, 255),
    })
    const whatIsHappeningField = useFieldWithError(
        IssueValuesKeys.WhatIsHappening,
        {
            disabled,
            defaultError:
                isCorrectStatus &&
                checkCharactersLimit(state?.whatIsHappening?.length, 500),
        }
    )
    const summaryField = useFieldWithError(IssueValuesKeys.Summary, {
        disabled,
        defaultError:
            isCorrectStatus &&
            checkCharactersLimit(state?.summary?.length, 1000),
    })
    const whyIsThisAProblemField = useFieldWithError(
        IssueValuesKeys.WhyIsThisAProblem,
        {
            disabled,
            defaultError:
                isCorrectStatus &&
                checkCharactersLimit(state?.whyIsThisAProblem?.length, 255),
        }
    )
    const howDoWeKnowWeHaveAProblemField = useFieldWithError(
        IssueValuesKeys.HowDoWeKnowWeHaveAProblem,
        {
            disabled,
            defaultError:
                isCorrectStatus &&
                checkCharactersLimit(
                    state?.howDoWeKnowWeHaveAProblem?.length,
                    255
                ),
        }
    )
    const whereDoesDidProblemHappenField = useFieldWithError(
        IssueValuesKeys.WhereDoesDidProblemHappen,
        {
            disabled,
            defaultError:
                isCorrectStatus &&
                checkCharactersLimit(
                    state?.whereDoesDidProblemHappen?.length,
                    255
                ),
        }
    )
    const whenDoesDidTheProblemHappenField = useFieldWithError(
        IssueValuesKeys.WhenDoesDidTheProblemHappen,
        {
            disabled,
            defaultError:
                isCorrectStatus &&
                checkCharactersLimit(
                    state?.whenDoesDidTheProblemHappen?.length,
                    255
                ),
        }
    )
    const whatAreCustomerExpectationsField = useFieldWithError(
        IssueValuesKeys.WhatAreCustomerExpectations,
        {
            disabled,
            defaultError:
                isCorrectStatus &&
                checkCharactersLimit(
                    state?.whatAreCustomerExpectations?.length,
                    500
                ),
        }
    )
    const whoIsAffectedByTheProblemField = useFieldWithError(
        IssueValuesKeys.WhoIsAffectedByTheProblem,
        { disabled }
    )
    const howManyDefectsFoundField = useFieldWithError(
        IssueValuesKeys.HowManyDefectsFound,
        {
            defaultHelperText:
                howManyDefectsFoundError &&
                `The field 'How many defects/units/parts are affected' must be between 0 and ${MAX_INTEGER}.`,
            defaultError: howManyDefectsFoundError,
            disabled,
        }
    )

    return (
        <div className={clsx(classNameProp)} {...other}>
            <Typography variant="h6">Issue description</Typography>
            <TextFieldLimit
                disabled={!isCorrectStatus}
                currentLength={state?.subject?.length}
                maxLength={255}
            >
                <TextField
                    {...ISSUE_VIEW_PAGE.DESCRIPTION.ISSUE_SUBJECT}
                    className={classes.field}
                    label="Issue Subject"
                    onChange={(e: any) => {
                        setState((prev) => ({
                            ...prev,
                            subject: e.target.value,
                        }))
                    }}
                    onBlur={() => handleFormChange('subject')}
                    value={state.subject}
                    {...subjectField}
                    error={!subjectField?.disabled && subjectField.error}
                    multiline
                />
            </TextFieldLimit>
            <Collapse in={issueType !== IssueTypes.Internal_NC}>
                <TextFieldLimit
                    disabled={!isCorrectStatus}
                    currentLength={state?.summary?.length}
                    maxLength={1000}
                >
                    <TextField
                        className={classes.field}
                        label="Issue summary"
                        {...dataTestId('ISSUE_VIEW_ISSUE_SUMMARY')}
                        onChange={(e: any) => {
                            setState((prev) => ({
                                ...prev,
                                summary: e.target.value,
                            }))
                        }}
                        onBlur={() => handleFormChange('summary')}
                        value={state.summary}
                        {...summaryField}
                        error={!summaryField?.disabled && summaryField.error}
                        multiline
                    />
                </TextFieldLimit>
            </Collapse>
            <TextFieldLimit
                disabled={!isCorrectStatus}
                currentLength={state?.whatIsHappening?.length}
                maxLength={500}
            >
                <TextField
                    {...ISSUE_VIEW_PAGE.DESCRIPTION.WHAT}
                    className={classes.field}
                    label="What is happening?"
                    onChange={(e: any) => {
                        setState((prev) => ({
                            ...prev,
                            whatIsHappening: e.target.value,
                        }))
                    }}
                    onBlur={() => handleFormChange('whatIsHappening')}
                    value={state.whatIsHappening}
                    {...whatIsHappeningField}
                    error={
                        !whatIsHappeningField?.disabled &&
                        whatIsHappeningField.error
                    }
                    multiline
                />
            </TextFieldLimit>
            <TextFieldLimit
                disabled={!isCorrectStatus}
                currentLength={state?.whyIsThisAProblem?.length}
                maxLength={255}
            >
                <TextField
                    {...ISSUE_VIEW_PAGE.DESCRIPTION.WHY}
                    className={classes.field}
                    label="Why is this a problem?"
                    onChange={(e: any) => {
                        setState((prev) => ({
                            ...prev,
                            whyIsThisAProblem: e.target.value,
                        }))
                    }}
                    onBlur={() => handleFormChange('whyIsThisAProblem')}
                    value={state.whyIsThisAProblem}
                    {...whyIsThisAProblemField}
                    error={
                        !whyIsThisAProblemField?.disabled &&
                        whyIsThisAProblemField.error
                    }
                    multiline
                />
            </TextFieldLimit>
            <TextFieldLimit
                disabled={!isCorrectStatus}
                currentLength={state?.howDoWeKnowWeHaveAProblem?.length}
                maxLength={255}
            >
                <TextField
                    {...ISSUE_VIEW_PAGE.DESCRIPTION.HOW}
                    className={classes.field}
                    label="How do we know we have a problem?"
                    value={state.howDoWeKnowWeHaveAProblem}
                    onChange={(e: any) => {
                        setState((prev) => ({
                            ...prev,
                            howDoWeKnowWeHaveAProblem: e.target.value,
                        }))
                    }}
                    onBlur={() => handleFormChange('howDoWeKnowWeHaveAProblem')}
                    {...howDoWeKnowWeHaveAProblemField}
                    error={
                        !howDoWeKnowWeHaveAProblemField?.disabled &&
                        howDoWeKnowWeHaveAProblemField.error
                    }
                    multiline
                />
            </TextFieldLimit>

            <Collapse in={issueType === IssueTypes.Internal_NC}>
                <TextField
                    {...ISSUE_VIEW_PAGE.DESCRIPTION.WHO}
                    {...whoIsAffectedByTheProblemField}
                    className={classes.field}
                    label="Who is affected by the problem? "
                    onChange={(e: any) => {
                        setState((prev) => ({
                            ...prev,
                            whoIsAffectedByTheProblem: e.target.value,
                        }))
                    }}
                    onBlur={() => handleFormChange('whoIsAffectedByTheProblem')}
                    value={state.whoIsAffectedByTheProblem}
                    multiline
                />
            </Collapse>
            <TextFieldLimit
                disabled={!isCorrectStatus}
                currentLength={state?.whereDoesDidProblemHappen?.length}
                maxLength={255}
            >
                <TextField
                    {...ISSUE_VIEW_PAGE.DESCRIPTION.WHERE}
                    className={classes.field}
                    label="Where does/did the problem happen?"
                    onChange={(e: any) => {
                        setState((prev) => ({
                            ...prev,
                            whereDoesDidProblemHappen: e.target.value,
                        }))
                    }}
                    onBlur={() => handleFormChange('whereDoesDidProblemHappen')}
                    value={state.whereDoesDidProblemHappen}
                    {...whereDoesDidProblemHappenField}
                    error={
                        !whereDoesDidProblemHappenField?.disabled &&
                        whereDoesDidProblemHappenField.error
                    }
                    multiline
                />
            </TextFieldLimit>
            <TextFieldLimit
                disabled={!isCorrectStatus}
                currentLength={state?.whenDoesDidTheProblemHappen?.length}
                maxLength={255}
            >
                <TextField
                    {...ISSUE_VIEW_PAGE.DESCRIPTION.WHEN}
                    className={classes.field}
                    label="When does/did the problem happen?"
                    onChange={(e: any) => {
                        setState((prev) => ({
                            ...prev,
                            whenDoesDidTheProblemHappen: e.target.value,
                        }))
                    }}
                    onBlur={() =>
                        handleFormChange('whenDoesDidTheProblemHappen')
                    }
                    value={state.whenDoesDidTheProblemHappen}
                    {...whenDoesDidTheProblemHappenField}
                    error={
                        !whenDoesDidTheProblemHappenField?.disabled &&
                        whenDoesDidTheProblemHappenField.error
                    }
                    multiline
                />
            </TextFieldLimit>

            <Collapse in={issueType !== IssueTypes.Internal_NC}>
                <TextFieldLimit
                    disabled={!isCorrectStatus}
                    currentLength={state?.whatAreCustomerExpectations?.length}
                    maxLength={500}
                >
                    <TextField
                        {...ISSUE_VIEW_PAGE.DESCRIPTION.EXPECTATIONS}
                        className={classes.field}
                        label="What are the customer's expectations?"
                        onChange={(e: any) => {
                            setState((prev) => ({
                                ...prev,
                                whatAreCustomerExpectations: e.target.value,
                            }))
                        }}
                        onBlur={() =>
                            handleFormChange('whatAreCustomerExpectations')
                        }
                        value={state.whatAreCustomerExpectations}
                        {...whatAreCustomerExpectationsField}
                        error={
                            !whatAreCustomerExpectationsField?.disabled &&
                            whatAreCustomerExpectationsField.error
                        }
                        multiline
                    />
                </TextFieldLimit>
            </Collapse>
            <Collapse in={issueType === IssueTypes.Internal_NC}>
                <TextField
                    {...ISSUE_VIEW_PAGE.DESCRIPTION.HOW_MANY_AFFECTED}
                    {...howManyDefectsFoundField}
                    className={classes.field}
                    label="How many defects/units/parts are affected? "
                    type="number"
                    onChange={(e: any) => {
                        if (!stopScroll) return
                        let value = Number(e.target.value)
                        value = value < 0 ? 0 : value
                        setState((prev) => ({
                            ...prev,
                            howManyDefectsFound: value,
                        }))
                    }}
                    onBlur={() => handleFormChange('howManyDefectsFound')}
                    value={state.howManyDefectsFound}
                    onWheel={() => setStopScroll(false)}
                    onMouseLeave={() => setStopScroll(true)}
                />
            </Collapse>
        </div>
    )
}

InformationAboutIssue.defaultProps = {
    lightMode: false,
    disabled: false,
}

export default memo(InformationAboutIssue)
