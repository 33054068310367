import { FAKE_AZURE_AD } from 'features'
import { initUserProfile } from 'store/dashboard'
import { UserProfile } from 'types/profile'
import {
    AccountInfo,
    AuthenticationResult,
    Configuration,
    EndSessionRequest,
    InteractionRequiredAuthError,
    IPublicClientApplication,
    PublicClientApplication,
    RedirectRequest,
    SilentRequest,
} from '@azure/msal-browser'
import { ClientApplication } from '@azure/msal-browser/dist/app/ClientApplication'
import dayjs from 'dayjs'

export const OVERRIDE_TOKEN_KEY = 'override-token'

export const msalConfig: Configuration = {
    auth: {
        authority: process.env.REACT_APP_AZURE_AD_AUTHORITY,
        clientId: process.env.REACT_APP_AZURE_AD_CLIENT_ID,
        redirectUri: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
        navigateToLoginRequestUrl: true,
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
    },
}

export const loginRequest: RedirectRequest = {
    scopes: [process.env.REACT_APP_API_ACCESS_SCOPE],
}

export const parseJwtPayload = (token: string): any => {
    if (!token) return
    var base64Url = token?.split('.')[1]
    var base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/')
    var jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join('')
    )

    return JSON.parse(jsonPayload)
}

export class MockPublicClientApplication
    extends ClientApplication
    implements IPublicClientApplication
{
    acquireTokenSilent = (
        _silentRequest: SilentRequest
    ): Promise<AuthenticationResult> => {
        const profile: TokenProfile = parseJwtPayload(
            localStorage?.getItem(OVERRIDE_TOKEN_KEY)
        )
        const expiresOn = new Date()
        expiresOn.setFullYear(expiresOn.getFullYear() + 1)
        return new Promise((resolve) => {
            resolve({
                accessToken: localStorage?.getItem(OVERRIDE_TOKEN_KEY),
                account: {
                    environment: '',
                    homeAccountId: '',
                    localAccountId: '',
                    tenantId: '',
                    username: profile.email,
                    name: profile.email,
                },
                authority: '',
                correlationId: '',
                expiresOn,
                idToken: localStorage?.getItem(OVERRIDE_TOKEN_KEY),
                fromCache: false,
                idTokenClaims: {},
                scopes: [''],
                tenantId: '',
                tokenType: '',
                uniqueId: '',
            })
        })
    }

    loginRedirect(): Promise<void> {
        return new Promise((resolve) => {
            resolve()
        })
    }

    logoutRedirect(logoutRequest?: EndSessionRequest): Promise<void> {
        return new Promise((resolve) => {
            window.location.reload()
        })
    }

    loginPopup(): Promise<AuthenticationResult> {
        return this.acquireTokenSilent({
            scopes: [],
        })
    }
    getActiveAccount(): AccountInfo {
        const profile: TokenProfile = parseJwtPayload(
            localStorage?.getItem(OVERRIDE_TOKEN_KEY)
        )

        return (
            profile && {
                environment: '',
                homeAccountId: '',
                localAccountId: '',
                tenantId: '',
                username: profile?.email,
                name: profile?.email,
            }
        )
    }

    setActiveAccount(account: AccountInfo): void {
        account = this.getActiveAccount()
    }
    getAllAccounts(): AccountInfo[] {
        const profile = localStorage?.getItem(OVERRIDE_TOKEN_KEY)
        return profile ? [this.getActiveAccount()] : []
    }
}

export interface TokenProfile {
    family_name: string
    given_name: string
    email: string
}

export const userProfileFromIdToken = async (): Promise<UserProfile> => {
    const tokenProfile: TokenProfile = parseJwtPayload(
        await getTokenId(
            msalInstance as PublicClientApplication,
            loginRequest.scopes
        )
    )
    const firstLetter = (word: string): string =>
        word?.trim()[0]?.toLocaleUpperCase() ?? ''
    const lastName = tokenProfile['family_name'] ?? null
    const firstName = tokenProfile['given_name'] ?? null
    const email = tokenProfile['email'] ?? null
    const profile: UserProfile = {
        ...initUserProfile,
        email,
        initials: [firstLetter(firstName), firstLetter(lastName)]
            .join('')
            .trim(),
        firstName,
        lastName,
    }
    return Object.freeze(profile)
}

export const getTokenId = async (
    msal: PublicClientApplication | MockPublicClientApplication,
    scopes: string[]
): Promise<string> => {
    const account = msal.getActiveAccount()
    if (account) {
        const request = {
            scopes: scopes,
            account: account,
        }

        const expiresOn = await msal
            .acquireTokenSilent(request)
            .then((res) => res.expiresOn)

        const refreshTime = dayjs(expiresOn).subtract(30, 'm').local()
        const currentTime = dayjs().local()

        const force = expiresOn ? refreshTime < currentTime : true

        return msal
            .acquireTokenSilent({ ...request, forceRefresh: force })
            .then((response) => {
                return response.idToken
            })
            .catch((error) => {
                console.log(error)
                if (
                    error instanceof InteractionRequiredAuthError ||
                    error === 'token_to_refresh'
                ) {
                    console.log(error)
                    console.log(error.errorCode)
                    msal.acquireTokenRedirect(request)
                }
            }) as Promise<string>
    }
}

export const getAccessToken = async (
    msal: PublicClientApplication | MockPublicClientApplication,
    scopes: string[]
): Promise<string> => {
    const account = msal.getActiveAccount()

    if (account) {
        const request = {
            scopes: scopes,
            account: account,
        }

        return msal
            .acquireTokenSilent(request)
            .then((response) => {
                return response.accessToken
            })
            .catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    msal.acquireTokenRedirect(request)
                }
            }) as Promise<string>
    }
}

export const msalInstance = FAKE_AZURE_AD
    ? new MockPublicClientApplication(msalConfig)
    : new PublicClientApplication(msalConfig)
