import { Typography } from '@mui/material'
import Accordion from '../../components/Base/Accordion'
import AccordionActions from '../../components/Base/AccordionActions'
import AccordionDetails from '../../components/Base/AccordionDetails'
import AccordionSummary from '../../components/Base/AccordionSummary'
import Button from '../../components/Base/Button'

import ExpandIcon from '@mui/icons-material/ExpandMore'
import { useDispatch, useSelector } from 'react-redux'
import { showSnackbar } from 'store/app'
import {
    IssueStoreState,
    ISSUE_SUBMIT_CALL,
    saveDraft,
    setExpandMode,
    setIssueActionFail,
    submitDraft,
} from 'store/issue'
import { RootState } from 'store/types'
import theme from 'styles/themeBase'
import { ActionsKeys, IssueValuesKeys } from 'types/enums'
import FlexGrow from '../../components/Base/FlexGrow'
import { appInsights } from '../../configs/appInsights'
import { ISSUE_VIEW_PAGE } from '../../consts/selectorIds'
import LightModeSwitch from '../components/LightModeSwitch'
import { useIssueAction } from '../matrix'
import CaptureContent from './CaptureContent'
import { checkCharactersLimit } from './utils'
import { makeStyles } from '@mui/styles'
import { AbbTheme } from 'styles/createAbbTheme'

const useStyles = makeStyles((theme: AbbTheme) => ({
    section: {
        marginBottom: `${theme.spacing(4)} !important`,
    },
    header: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(7),
    },
}))

const CaptureContainer = (props: any) => {
    const classes = useStyles(props)
    const dispatch = useDispatch()

    const canSubmit = useIssueAction(ActionsKeys.Submit)
    const canSaveCapture = useIssueAction(ActionsKeys.SaveCaptureSection)
    const { captureSection, expanded } = useSelector<
        RootState,
        IssueStoreState
    >((state) => state.issue)
    if (!captureSection) {
        return null
    }
    const handleSaveIssue = () => {
        appInsights.trackEvent({
            name: 'Save issue',
        })
        dispatch(saveDraft())
    }
    const handleSubmitIssue = () => {
        if (checkCharactersLimit(captureSection?.summary?.length, 1000)) {
            dispatch(showSnackbar('Issue not submitted!', true))
            dispatch(
                setIssueActionFail(ISSUE_SUBMIT_CALL, {
                    status: 412,
                    errors: [
                        {
                            field: IssueValuesKeys.Summary,
                            message:
                                'The field Issue Summary must be a string with a maximum length of 1000.',
                        },
                    ],
                })
            )
            return
        }
        dispatch(submitDraft())
    }
    return (
        <>
            <Accordion
                className={classes.section}
                expanded={Boolean(expanded?.capture)}
                onChange={(e, value) =>
                    dispatch(setExpandMode('capture', value))
                }
            >
                <AccordionSummary
                    expandIcon={<ExpandIcon />}
                    {...ISSUE_VIEW_PAGE.ISSUE_CAPTURE.EXPANSION_PANEL}
                >
                    <Typography variant="h5">Issue capture</Typography>
                    <div
                        className={classes.header}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {Boolean(expanded?.capture) && <LightModeSwitch />}
                    </div>
                </AccordionSummary>
                <AccordionDetails
                    {...ISSUE_VIEW_PAGE.ISSUE_CAPTURE.DETAILS_CONTAINER}
                >
                    <CaptureContent />
                </AccordionDetails>
                <AccordionActions style={{ marginTop: theme.spacing(3) }}>
                    {!canSaveCapture.disabled && (
                        <Button
                            onClick={handleSaveIssue}
                            {...ISSUE_VIEW_PAGE.ISSUE_CAPTURE.ACTION_BUTTONS
                                .SAVE}
                        >
                            Save
                        </Button>
                    )}
                    <FlexGrow />
                    {!canSubmit.disabled && (
                        <Button
                            {...ISSUE_VIEW_PAGE.ISSUE_CAPTURE.ACTION_BUTTONS
                                .SUBMIT_ISSUE}
                            eventName="Submit issue"
                            color="secondary"
                            onClick={handleSubmitIssue}
                        >
                            Submit issue
                        </Button>
                    )}
                </AccordionActions>
            </Accordion>
        </>
    )
}

export default CaptureContainer
